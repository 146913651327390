<template>
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 569.3 569.3">
  <g id="Layer_1-2" data-name="Layer 1">
    <path d="m284.6,0C127.4,0,0,127.4,0,284.6s127.4,284.6,284.6,284.6,284.6-127.4,284.6-284.6S441.9,0,284.6,0Zm-55.9,404.7v-237.3l156.6,118.7-156.6,118.7Z" fill="#E20034" stroke-width="0"/>
  </g>
</svg>
</template>

<script>
export default {
    name: "PlayIcon",
    props: {},
    data: () => ({
    }),
};
</script>