import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import { useGlobalStore } from "@/stores/global";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { pageClass: "page-home" },
  },
  {
    path: "/our-work",
    name: "OurWorkPage",
    component: () => import("../views/OurWorkPage.vue"),
    meta: { pageClass: "page-work" },
  },
  {
    path: "/our-work/asahi",
    name: "AsahiWorkPage",
    component: () => import("../views/work-page/Asahi.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/britvic-mixers",
    name: "BritvicMixersPage",
    component: () => import("../views/work-page/BritvicMixers.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/pepsi-max",
    name: "PepsiMaxPage",
    component: () => import("../views/work-page/PepsiMax.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/lipton-ice-tea",
    name: "LiptonPage",
    component: () => import("../views/work-page/Lipton.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/jti-human-rights",
    name: "HumanRightsPage",
    component: () => import("../views/work-page/HumanRights.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/marriott",
    name: "MarriottPage",
    component: () => import("../views/work-page/Marriott.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/delonghi",
    name: "DeLonghiPage",
    component: () => import("../views/work-page/DeLonghi.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/our-work/campari",
    name: "CampariPage",
    component: () => import("../views/work-page/Campari.vue"),
    meta: { pageClass: "page-case-study" },
  },
  {
    path: "/disciplines",
    name: "DisciplinesPage",
    component: () => import("../views/DisciplinesPage.vue"),
    meta: { pageClass: "page-disciplines" },
  },
  {
    path: "/our-people",
    name: "OurPeoplePage",
    component: () => import("../views/OurPeoplePage.vue"),
    meta: { pageClass: "page-people" },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPage.vue"),
    meta: { pageClass: "page-privacy" },
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: () => import("../views/CookiePage.vue"),
    meta: { pageClass: "page-cookies" },
  },
  {
    path: "/scrolling",
    name: "Scroll Test",
    component: () => import("../views/ScrollTest.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const element = document.querySelector(to.hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          resolve();
        }, 300); // Delay to allow DOM to update
      });
    }
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const globalStore = useGlobalStore();

  if (!globalStore.isDataLoaded) {
    await globalStore.fetchData();
  }

  // Check if the route has specific asset loading requirements
  if (to.meta.assets) {
    const assetPromises = to.meta.assets.map((asset) => {
      if (asset.type === "image") {
        return globalStore.loadImage(asset.src);
      } else if (asset.type === "backgroundImage") {
        return globalStore.loadBackgroundImage(asset.src);
      }
      // Add other asset types as needed
    });
    await Promise.all(assetPromises);
  }
  next();
});

export default router;
