<template>
  <v-app :class="pageClass">
    <transition name="slide-up">
      <div v-if="isLoading" class="loading-overlay">
        <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="80" r="10" fill="#e20034">
            <animate attributeName="cy" values="80; 20; 80" dur="1s" repeatCount="indefinite" keyTimes="0; 0.3; 1"
              keySplines=".5 0 .5 1; .5 0 .5 1" calcMode="spline" />
          </circle>
        </svg>
      </div>
    </transition>

    <div v-if="isMobile && isLandscape" class="landscape-warning">
      <p>Please rotate your device to portrait mode for the best experience.</p>
    </div>
    <div v-else>
      <AppMenu />
      <v-main>
        <router-view :class="{ withMenu: menuOpen }" @background-loaded="onBackgroundLoaded" />
      </v-main>
    </div>
  </v-app>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGlobalStore } from '@/stores/global';
import AppMenu from '@/components/AppMenu';

const menuOpen = ref(false);
const route = useRoute();
const router = useRouter();
const globalStore = useGlobalStore();
const isLoading = ref(true);
const isMobile = ref(/Mobi|Android/i.test(navigator.userAgent));
const isLandscape = ref(window.matchMedia("(orientation: landscape)").matches);
const isDataLoaded = ref(false);

const onBackgroundLoaded = () => {
  isDataLoaded.value = true;
};

const pageClass = computed(() => route.meta.pageClass || '');

const checkOrientation = () => {
  isLandscape.value = window.matchMedia("(orientation: landscape)").matches;
};

const checkAllAssetsLoaded = () => {
  return new Promise((resolve) => {
    if (document.readyState === "complete") {
      resolve();
    } else {
      window.addEventListener("load", resolve, { once: true });
    }
  });
};

const waitForAssets = async () => {
  return new Promise((resolve) => {
    const observer = new PerformanceObserver((list, observer) => {
      const resources = list.getEntriesByType("resource");
      const pendingAssets = resources.filter(res => !res.responseEnd);

      if (pendingAssets.length === 0) {
        resolve();
        observer.disconnect();
      }
    });

    observer.observe({ entryTypes: ["resource"] });

    checkAllAssetsLoaded().then(resolve);
  });
};

// Function to handle page load logic
const loadPageAssets = async () => {
  isLoading.value = true;
  await globalStore.fetchData();
  await nextTick(); // Ensure Vue renders new page content first
  await waitForAssets();
  isLoading.value = false;
};

onMounted(async () => {
  window.addEventListener("resize", checkOrientation);
  await loadPageAssets();
});

onUnmounted(() => {
  window.removeEventListener("resize", checkOrientation);
});

// Watch for route changes and reload assets
router.beforeEach(async (to, from, next) => {
  isLoading.value = true;
  next();
});

router.afterEach(async () => {
  await loadPageAssets();
});
</script>


<style lang="scss">
@import '@/assets/scss/style';

$panel-brands-bg-color: #E20034;
$text-light: #ffffff;
$text-dark: #000000;

@function best-text-color($bg-color, $light-color, $dark-color) {
  $lightness: lightness($bg-color);
  @return if($lightness > 50%, $dark-color, $light-color);
}

.header {
  height: 77px;
}

.page-disciplines, .page-case-study {
  .menu a {
    color: #ffffff !important;
  }

  .burger-menu path {
    fill: #FFFFFF;
  }

  .logo path {
    fill: #FFFFFF;
  }
}

.page-our-people {
  .menu a {
    color: #E20034 !important;
  }

  .burger-menu path {
    fill: #FFFFFF;
  }

  .logo path {
    fill: #FFFFFF;
  }
}


.page-case-study .footer__branding-container {
  height: unset;
}

.landscape-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-family: "Bison" sans-serif;
  z-index: 9999;
  text-transform: uppercase;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-in-out;
  z-index: 19999;

  h2 {
    color: #555555;
  }
}

.loader {
  font-size: 1.5rem;
  font-weight: bold;
  color: #555555;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.6s ease-in-out;
}

.slide-up-leave-to {
  transform: translateY(-100%);
}
</style>
