<template>
    <!-- <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="35"
        class="burger-menu">
        <g id="We_Upstart" data-name="We Upstart">
            <g>
                <circle cx="29.8" cy="29.8" r="29.8" fill="#e20033" stroke-width="0" />
                <g>
                    <path d="M20,20 H40" fill="none" stroke="#fff9f3" stroke-width="2" class="stroke-1" />
                    <path d="M20,30 H40" fill="none" stroke="#fff9f3" stroke-width="2" class="stroke-2" />
                    <path d="M20,40 H40" fill="none" stroke="#fff9f3" stroke-width="2" class="stroke-3" />
                </g>
            </g>
        </g>
    </svg> -->


    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.6 59.6" width="35"
        class="burger-menu">
        <g id="Layer_1-2" data-name="Layer 1">
            <path
                d="m29.8,0C13.3,0,0,13.3,0,29.8s13.3,29.8,29.8,29.8,29.8-13.3,29.8-29.8S46.3,0,29.8,0Zm8.9,40.6h-17.8c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h17.8c.7,0,1.3.6,1.3,1.3s-.6,1.3-1.3,1.3Zm0-9.5h-17.8c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h17.8c.7,0,1.3.6,1.3,1.3s-.6,1.3-1.3,1.3Zm0-9.5h-17.8c-.7,0-1.3-.6-1.3-1.3s.6-1.3,1.3-1.3h17.8c.7,0,1.3.6,1.3,1.3s-.6,1.3-1.3,1.3Z"
                fill="#e20033" stroke-width="0" />
        </g>
    </svg>
</template>

<script>
export default {
    name: "MenuIcon",
    props: {},
    data: () => ({
    }),
};
</script>