<template>
<svg xmlns="http://www.w3.org/2000/svg" width="92.147" height="34.5" viewBox="0 0 92.147 34.5" class="logo white-fill">
    <g id="Group_371" data-name="Group 371" transform="translate(-2309.255 -904.5)">
      <path id="Path_758" data-name="Path 758" d="M2380.06,907.73a3.23,3.23,0,1,1-3.231-3.23,3.232,3.232,0,0,1,3.231,3.23" fill="#e30033"/>
      <path id="Path_759" data-name="Path 759" d="M2309.255,914.194h4.889v24.415h-4.889Zm65.13,24.415h4.889V914.194h-4.889Zm-42.923-20.834a8.31,8.31,0,0,1,7.5,4.347l2.886-2.886a12.846,12.846,0,0,0-10.384-5.592c-7.286,0-13.215,5.02-13.215,12.591S2324.176,939,2331.462,939a14.1,14.1,0,0,0,10.384-4.61v-9.151H2332.8v4.141h4.623v3.451a9.438,9.438,0,0,1-5.966,1.993,8.326,8.326,0,0,1-8.684-8.589c0-5.073,3.7-8.46,8.684-8.46m61.783,5.741c-2.513-.66-4.339-1.174-4.339-2.824,0-1.466,1.109-2.493,3.2-2.493a8.722,8.722,0,0,1,5.285,1.828l3.243-3.242a11.382,11.382,0,0,0-8.2-3.133c-5.124,0-8.254,3.3-8.254,7.37,0,5.464,4.567,6.565,8.124,7.482,2.513.66,4.4,1.282,4.4,3.116,0,1.394-1.239,2.751-3.785,2.751a7.826,7.826,0,0,1-6.782-3.093l-3.276,3.277c2.4,3.051,5.54,4.37,9.83,4.363,5.874-.01,8.711-3.375,8.711-7.848,0-5.428-4.567-6.638-8.157-7.554m-34.364-9.868a10.14,10.14,0,0,0-7.138,2.925v-2.379h-5.136v24.284h5.136V924.216a6.079,6.079,0,1,1,12.158,0v14.262h5.136V923.773a10.141,10.141,0,0,0-10.156-10.125" fill="#e30033"/>
    </g>
  </svg>
  </template>

<script>
export default {
    name: "IgnisLogo",
    props: {},
    data: () => ({
    }),
};
</script> 