<template>
  <div>
    <div id="menu-overlay" :class="{ open: isOpen }">
      <div id="overlay-logo">
        <router-link to="/"></router-link>
      </div>
      <div id="menu-icon" @click="toggleMenu">
        <MenuIconClose></MenuIconClose>
      </div>
      <div id="menu-overlay__menu">
        <ul>
          <li @click="toggleMenu"><router-link :to="{ path: '/', hash: '#about-us' }" class="about-us-link">About
              Us</router-link></li>
          <li @click="toggleMenu"><router-link :to="{ path: '/our-work' }" class="our-work-link">Our
              Work</router-link></li>
          <li @click="toggleMenu"><router-link to="/disciplines"
              class="native disciplines-link">Disciplines</router-link>
          </li>
          <li @click="toggleMenu"><router-link to="/our-people" class="our-people-link">Our People</router-link></li>
          <li @click="toggleMenu"><router-link class="contact-link"
              :to="{ path: '/', hash: '#contact' }">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
    <header class="header">
      <div class="header__nav">
        <div id="logo">
          <router-link to="/">
            <IgnisLogo></IgnisLogo>
          </router-link>
        </div>
        <div class="menu">
          <router-link :to="{ path: '/our-work' }" class="our-work-link">WORK</router-link>
          <a href="mailto:jackie@ignis.co.uk" class="connect">CONNECT</a>
          <div class="menu-icon" @click="toggleMenu" :class="{ open: isOpen }">
            <MenuIcon></MenuIcon>
          </div>
        </div>
      </div>
    </header>
    
    <div v-if="overlay" class="custom-overlay" @click="closeVideoOverlay">
      <div class="video-container" ref="videoContainer">
        <video id="video3" ref="video3" poster="https://ignisshowreel.s3.eu-west-1.amazonaws.com/poster.png" playsinline
          autoplay src="https://ignisshowreel.s3.eu-west-1.amazonaws.com/ignis_sizzle_reel_2025_1080p.mp4"
          type="video/mp4">
          <source src="https://ignisshowreel.s3.eu-west-1.amazonaws.com/ignis_sizzle_reel_2025_1080p.mp4"
            type="video/mp4" />
        </video>
        <div class="close-video-button">
          <img :src="require('@/assets/images/close_button.svg')" @click="closeVideoOverlay" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MenuIcon from "@/components/svg/MenuIcon";
import MenuIconClose from "@/components/svg/MenuIconClose";
import IgnisLogo from "@/components/svg/IgnisLogo";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "AppMenu",
  components: {
    MenuIcon,
    MenuIconClose,
    IgnisLogo,
  },
  setup() {
    const isOpen = ref(false);
    const isClosing = ref(false);

    const overlay = ref(false);
    const isExpanded = ref(false);
    const video3 = ref(null); 

    // Toggle Menu
    const toggleMenu = () => {
      if (isOpen.value) {
        isClosing.value = true;
        setTimeout(() => {
          isOpen.value = false;
          isClosing.value = false;
        }, 300); 
      } else {
        isOpen.value = true; 
      }
    };

    // const togglePlayPauseVid3 = () => {
    //   const videoElement = video3.value; 
    //   if (videoElement) {
    //     if (videoElement.paused) {
    //       videoElement.play(); // Play video
    //     } else {
    //       videoElement.pause(); // Pause video
    //     }
    //   }
    // };

    const playVideo = () => {
      const videoElement = video3.value;
      if (videoElement) {
        videoElement
          .play()
          .then(() => {
            videoElement.muted = false;
          })
          .catch((error) => {
            console.error('Error playing the video:', error);
          });
      }
      overlay.value = true;

    };

    const closeVideoOverlay = () => {
      if (!isExpanded.value) return; 

      isExpanded.value = false;
      overlay.value = false;

      const video3 = document.getElementById("video3");
      if (video3) {
        video3.pause(); 
        video3.currentTime = 0; 
      }
    };

    const showAndPlayVideo = () => {
      isExpanded.value = true;
      nextTick(() => {
        playVideo(); 
      });
    };

    let previousScroll = window.scrollY;

    onMounted(() => {
      const menu = document.querySelector('.header__nav');

      window.addEventListener("scroll", () => {
        const currentScroll = window.scrollY;

        // Menu show/hide on scroll based on direction
        if (currentScroll === 0) {
          gsap.to(menu, { y: "0%", duration: 0.3, ease: "power2.out" });
        } else if (currentScroll > previousScroll && previousScroll > 0) {
          gsap.to(menu, { y: "-100%", duration: 0.3, ease: "power2.out" });
        } else if (currentScroll < previousScroll) {
          gsap.to(menu, { y: "0%", duration: 0.3, ease: "power2.out" });
        }

        previousScroll = currentScroll;
      });
    });

    return {
      isOpen,
      overlay,
      isExpanded,
      video3,
      toggleMenu,
      closeVideoOverlay,
      showAndPlayVideo,
    };
  },
};
</script>

<style lang="scss">
#logo {
  z-index: 1501;
  transition: all 0.3s ease;
}

.menu {
  height: 100%;
  margin-top: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: all 0.3s all;

  a {
    margin-right: 30px;
    font-family: "Bison", sans-serif;
    font-size: 2.2em;
    line-height: 0.9;
    font-weight: 300;
    text-align: center;
    color: $text-default;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .menu-icon {
    cursor: pointer;
    padding: 0 10px;
    transition: all 0.3s ease;
  }


  @media screen and (max-width: 1024px) {
    a {
      font-size: 1.6em;
      margin-right: 10px;
    }

  }

  @media screen and (max-width: 768px) {
    a.connect {
      display: none;
    }
  }
}


#menu-overlay {
  background: #000;
  height: 100%;
  width: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 33000;
  display: flex;
  /* Keep flex for layout, but use transform instead of display: none */
  align-items: start;
  justify-content: center;
  overflow: hidden;
  transform: translateX(-100%);
  /* Initially hidden to the left */
  transition: transform 0.3s ease-in-out;
  /* Smooth transition */

  &.open {
    transform: translateX(0);
    /* Slide in from the left */
  }

  &.closing {
    transform: translateX(100%);
    /* Slide out to the right */
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-width: 960px;
    width: 960px;
    height: 100vh;
    overflow: hidden;
    transition: all 0.2s ease-in;

    ul {
      padding-left: 20px;
      overflow: hidden;

      li {
        margin: 4px 0;
      }
    }

    a {
      font-family: $font-stack-bison;
      font-size: 5em;
      line-height: 1;
      padding: 0 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 110px;
      background-position: 0 50%;
      color: #ffffff;
      transition: 0.1s;

      &:hover {
        font-family: $font-stack-better-times;
        color: #03ffff;
        font-size: 5.6em;
      }

      @media screen and (max-width: 1024px) {
        font-size: 3em;
        height: 70px;
      }
    }
  }

  #overlay-logo {
    position: absolute;
    left: 20.5px;
    top: 20.5px;

    .logo {
      path {
        fill: white !important;
      }
    }
  }

  #menu-icon {
    position: absolute;
    right: 67px;
    top: 36px;
    cursor: pointer;
    width: 40px;

    @media screen and (max-width: 1024px) {
      right: 24px;
      top: 9px;
    }
  }
}

.menu-overlay-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.menu-overlay-bg.open {
  opacity: 1;
  pointer-events: all;
}

.work-video-overlay {
  padding: 60px;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
  }
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

}

.video-container {
  position: relative;
  width: 75%;
  background: black;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.close-video-button {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 310000;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>