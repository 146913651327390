<template>
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.6 62.6">
  <g id="We_Upstart" data-name="We Upstart">
    <g>
      <circle cx="31.3" cy="31.3" r="31.3" stroke-width="0"/>
      <g>
        <line x1="44.9" y1="17.8" x2="17.8" y2="44.9" fill="none" stroke="#fff9f3" stroke-miterlimit="10" stroke-width="2.5"/>
        <line x1="17.8" y1="17.8" x2="44.9" y2="44.9" fill="none" stroke="#fff9f3" stroke-miterlimit="10" stroke-width="2.5"/>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
export default {
    name: "MenuIconClose",
    props: {},
    data: () => ({
    }),
};
</script>