import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        isDataLoaded: false,
        loadingProgress: 0,
        images: {}, // Store resolved image paths
    }),
    actions: {
        async fetchData() {
            try {
                // Store image paths dynamically
                const images = [
                    new URL('@/assets/images/hero_asahi.jpg', import.meta.url).href,
                    new URL('@/assets/images/hero_campari.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/asahi/Asset2.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/asahi/Asset3.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/asahi_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/pepsi_our_work_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/britvic_mixers.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/human_rights_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/lipton_ice_tea_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/campari_our_work_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/marriott_NFL_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/delonghi_our_work_thumb.png', import.meta.url).href,
                    new URL('@/assets/images/home_thumb_4.png', import.meta.url).href,
                    new URL('@/assets/images/home_thumb_3.png', import.meta.url).href,
                    new URL('@/assets/images/home_thumb_2.png', import.meta.url).href,
                    new URL('@/assets/images/home_thumb_1.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/campari/campari_hero.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/delonghi/hero.png', import.meta.url).href,
                    new URL('@/assets/images/our-work/marriott/marriott_hero.png', import.meta.url).href,
                    new URL('@/assets/images/experiential_1.png', import.meta.url).href,
                    new URL('@/assets/images/experiential_3.png', import.meta.url).href,
                    new URL('@/assets/images/social_thumb_5.png', import.meta.url).href,
                    new URL('@/assets/images/social_thumb_4.png', import.meta.url).href,
                    new URL('@/assets/images/culture/thumb_1.png', import.meta.url).href,
                    new URL('@/assets/images/culture/thumb_3.png', import.meta.url).href,
                    new URL('@/assets/images/experiential_text.png', import.meta.url).href,
                    new URL('@/assets/images/experiential_script.png', import.meta.url).href,
                ];

                // Preload images
                const imagePromises = images.map(this.loadImage);

                // Add externally hosted images
                const externalImages = [
                    'https://ignisshowreel.s3.eu-west-1.amazonaws.com/britvic-poster.png',
                    'https://ignisshowreel.s3.eu-west-1.amazonaws.com/true-britvic-poster-animation.png',
                    'https://ignisshowreel.s3.eu-west-1.amazonaws.com/lipton-poster-animation.png',
                ];

                await Promise.all([...imagePromises, ...externalImages.map(this.loadImage)]);

                this.isDataLoaded = true;
                this.loadingProgress = 100;
            } catch (error) {
                console.error('Error loading data:', error);
            }
        },
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(src);
                img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
                img.src = src;
            });
        }
    }
});
